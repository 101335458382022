const translation = {
  translation: {
    // Data model validation errors (joi)
    ARRAY_BODY_ERROR: 'La solicitud entregada está mal estructurada',
    OBJECT_BODY_ERROR: 'La solicitud entregada está mal estructurada',
    // There will be a key included in the front of each sentence
    WRONG_TYPE: 'es de tipo incorrecto',
    WRONG_DATE: 'tiene formato de fecha incorrecto',
    WRONG_EMAIL: 'tiene formato de email incorrecto',
    EMPTY: 'no puede estar vacío',
    MIN_STRING: 'posee menos caracteres de lo permitido',
    MAX_STRING: 'posee más caracteres de lo permitido',
    MIN_NUMBER: 'es inferior al límite númerico permitido',
    MAX_NUMBER: 'es superior al límite númerico permitido',
    REQUIRED: 'es un campo requerido',
    ALPHANUM: 'debe ser alfanumérico',

    // General
    DATABASE_ERROR: 'Ha ocurrido un error a nivel del servidor',
    PARAM_REQUIRED: 'Se deben entregrar los parámetros correspondientes',
    DATA_EXISTS: 'El registro entregado ya existe',
    DATA_NOT_EXISTS: 'El registro entregado no existe',
    DATA_NOT_RELATED: 'El registro entregado no está relacionado',
    NOT_EXISTS: 'El o los parámetros entregados no existen',
    BODY_ERROR: 'La solicitud entregada está mal estructurada',
    CREATED_SUCCESS: 'Registro(s) creado(s) exitosamente',
    UPDATE_SUCCESS: 'Registro(s) actualizado(s) exitosamente',
    DELETE_SUCCESS: 'Registro(s) borrado(s) exitosamente',
    INVALID_ACTION: 'La acción realizada es inválida',

    // HTTP-Error
    'Bad Request': 'La solicitud es inválida',
    'Internal Server Error': 'Ha ocurrido un error a nivel del servidor',
    'Not Found': 'No se ha encontrado lo solicitado',

    // Inventory orders
    STATUS_INVENTORY_ERROR: 'Error',
    INVENTORY_ORDER_NOT_FOUND: 'La orden de inventario entregada no existe',
    INVENTORY_ORDER_NOT_RELATED: 'La orden de inventario entregada es inválida',
    INVENTORY_TYPE_ERROR: 'El tipo de orden entregado es inválido',
    INVENTORY_ORDER_ACCEPTED_SUCCESS: 'La orden ha sido aceptada exitosamente',
    INVALID_INVENTORY_ORDER_STATUS: 'El estado entregado es inválido',
    INVALID_INVENTORY_ORDER_TYPE: 'El tipo entregado es inválido',
    INVALID_INVENTORY_ORDER_PRODUCTS:
      'Los productos no tinen relación con la orden de inventario',
    WARECLOUD_INVENTORY_ERROR:
      'El warecloud no cuenta con stock suficiente para realizar la acción',

    // Wareclouds
    WARECLOUD_NOT_FOUND:
      'El warecloud no se ha podido encontrar en los registros',
    WARECLOUD_PICKUP_POINT_NOT_FOUND:
      'El warecloud de punto de retiro no se encuentra en los registros',

    // Orders
    ORDER_WITHOUT_A_ROUTE: 'La orden no cuenta con una ruta asociada',
    ORDER_WITHOUT_A_CLOUDER: 'La orden no cuenta con un clouder asociado',
    INVALID_ORDER_STATUS:
      'La orden no cuenta con un estado válido para la acción',
    ROUTE_NOT_FOUND: 'La ruta no se encuentra en nuestros registros',
    ORDER_TRANSFER_CONFIRMATION_EXISTS: 'La orden de confirmación ya existe',
    ORDER_NOT_FOUND: 'La orden no se encuentra en nuestros registros',
    ORDER_NOT_RELATED: 'La orden no se encuentra vinculada al usuario',

    // Misc
    CITY_NOT_FOUND: 'La ciudad entregada no existe',
    CITY_NOT_RELATED: 'La ciudad entregada no es válida para la acción',
  },
};

export default translation;
