// Core
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Layouts
import ClouderRoutes from './ClouderRoutes';

// Custom routes
import PrivateRoute from './PrivateRoute';

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path="/login" module="login" />
      <Route component={ClouderRoutes} />
    </Switch>
  );
};

export default Routes;
