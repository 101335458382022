// Core
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Dependencies
import { Row, Tab, Nav } from 'react-bootstrap';
import MainLayoutWrapper from '../common/_layouts/MainLayoutWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPeopleCarry,
  faLaptopHouse,
} from '@fortawesome/free-solid-svg-icons';

// Components
import SearchBar from './OrderSearchBar';
import OrdersToDeliver from './OrdersToDeliver';
import OrdersToReceive from './OrdersToReceive';
import OrderStateChangeModal from './OrderStateChangeModal';

// Stylesheets
import './orders.scss';

// Api
import {
  getAssignedEcommerces,
  getAssignedWareclouds,
} from '../../api/miscellaneous';

// Reducers
import { setWareclouds, setEcommerces } from './orderSlice';
import { setMessage } from '../toasts/toastSlice';

// Layout component for login
const OrdersPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ order: {} });
  const [activeTab, setActiveTab] = useState('toReceive');
  const { isPickupPoint } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Get and set wareclouds assigned to the logged in ecommerce
  useEffect(() => {
    const warecloudsApi = async () => {
      try {
        const ecommercesResponse = await getAssignedEcommerces();

        const warecloudsResponse = await getAssignedWareclouds();

        dispatch(setWareclouds(warecloudsResponse.data));
        dispatch(setEcommerces(ecommercesResponse.data));
      } catch (err) {
        dispatch(
          setMessage({
            body: `${err}`,
            type: 'error',
          })
        );
      }
    };

    warecloudsApi();
  }, [dispatch, isPickupPoint]);

  const handleStateChangeModal = (payload) => {
    setShowModal(true);
    setModalContent(payload);
  };

  // Returns the tab to be rendered at a time
  const renderTab = () => {
    switch (activeTab) {
      case 'toReceive':
        return (
          <OrdersToReceive handleStateChangeModal={handleStateChangeModal} />
        );
      case 'toDeliver':
        return (
          <OrdersToDeliver handleStateChangeModal={handleStateChangeModal} />
        );
      default:
        break;
    }
  };

  return (
    <MainLayoutWrapper>
      <OrderStateChangeModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        title={modalContent.title}
        order={modalContent.order}
      />
      <SearchBar placeholder="Busca por código del pedido" />

      <Tab.Container id="tabs" defaultActiveKey={activeTab}>
        <Row className="switch mt-4 mb-3">
          <Nav variant="pills" className="w-100 h-100">
            <Nav.Item className="switch__item">
              <Nav.Link
                className="switch__link switch__link--rounded-left"
                eventKey="toReceive"
                onClick={() => setActiveTab('toReceive')}
              >
                <FontAwesomeIcon
                  icon={faLaptopHouse}
                  className="switch__icon"
                />
                Por recibir
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="switch__item">
              <Nav.Link
                className="switch__link switch__link--rounded-right"
                eventKey="toDeliver"
                onClick={() => setActiveTab('toDeliver')}
              >
                <FontAwesomeIcon
                  icon={faPeopleCarry}
                  className="switch__icon"
                />
                Por entregar
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
        {renderTab()}
      </Tab.Container>
    </MainLayoutWrapper>
  );
};

export default OrdersPage;
