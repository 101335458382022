// Core
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// API
import { verifyToken, refreshToken } from '../api/auth';

// Token utils
import {
  retrieveRefreshToken,
  saveAccessToken,
  saveRefreshToken,
  removeToken,
} from '../utils/token';

// Reducers
import { setIsAuthorized, setUsername } from '../features/login/loginSlice';

const useTokenRefresh = (module) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const scopedRefreshToken = refreshToken;
    const verifyAPI = async () => {
      try {
        const tokenResponse = await verifyToken();

        if (tokenResponse.data.type !== 'clouderPickup') {
          dispatch(setIsAuthorized(false));
          removeToken();
        } else {
          dispatch(setUsername(tokenResponse.data.username));
          dispatch(setIsAuthorized(true));
        }
      } catch (err) {
        if (retrieveRefreshToken()) {
          try {
            // If current access token is invalid, refresh it
            const refreshResponse = await scopedRefreshToken({
              token: retrieveRefreshToken(),
            });

            const { accessToken, refreshToken } = refreshResponse.data;

            // Save both newly generated tokens
            saveAccessToken(accessToken);
            saveRefreshToken(refreshToken);

            dispatch(setUsername(refreshResponse.data.username));
            dispatch(setIsAuthorized(true));
          } catch (err) {
            dispatch(setIsAuthorized(false));
            removeToken();
          }
        }
      }
      setIsLoading(false);
    };

    verifyAPI();
  }, [dispatch, module]);

  return [isLoading];
};

export default useTokenRefresh;
