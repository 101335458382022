import createAuthorizedAPI from './index';

const cloudersURL = process.env.REACT_APP_CLOUDER_PICKUP_API_URL;
const ordersURL = process.env.REACT_APP_ORDERS_API_URL;

const { post } = createAuthorizedAPI(cloudersURL);
const { post: confirmPost } = createAuthorizedAPI(ordersURL);

const ordersAPI = {
  // Returns an object with count and rows (array) of orders to pickup
  fetchOrdersToPickup: ({ page, ...payload }) => {
    return post(`/orders/pickupEcommerce/page/${page}`, payload);
  },
  // Returns an object with count and rows (array) of orders in route
  fetchOrdersInRoute: ({ page, ...payload }) => {
    return post(`/orders/inRouteWarecloud/page/${page}`, payload);
  },
  // Expected outcome: order has a order confirmation id
  confirmDeliveryToWarecloud: ({ id_order, ...payload }) => {
    return post(`/orders/${id_order}/deliverToWarecloud`, payload);
  },
  // Expected outcome: order status is changed
  confirmPickupFromEcommerce: ({ id_order, ...payload }) => {
    return confirmPost(
      `/clouderPickups/orders/${id_order}/pickupEcommerce`,
      payload
    );
  },
};

export const {
  fetchOrdersToPickup,
  fetchOrdersInRoute,
  confirmDeliveryToWarecloud,
  confirmPickupFromEcommerce,
} = ordersAPI;
