// Core
import React from 'react';

// Dependencies
import { Button, Spinner } from 'react-bootstrap';

// Button to be used to toggle data submission that
// includes an "is submitting" process.
const ButtonWithLoader = ({
  isSubmitting,
  handleSubmit,
  defaultLabel,
  isSubmittingLabel,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={(e) => {
        if (handleSubmit) {
          handleSubmit(e);
        }
      }}
      className="my-2"
      type={handleSubmit ? '' : 'submit'}
    >
      {isSubmitting && (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mr-1"
        />
      )}
      {isSubmitting ? isSubmittingLabel : defaultLabel}
    </Button>
  );
};

export default ButtonWithLoader;
