// Core
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Languages
import es from './config/languages/es';

// Resources
const resources = {
  es,
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
