import createAuthorizedAPI from './index';

const baseURL = process.env.REACT_APP_AUTH_API_URL;

const { get, post, loginPost } = createAuthorizedAPI(baseURL);

const authAPI = {
  // Requests session token from given credentials
  login: (payload) => {
    return loginPost(
      '/login/clouderPickup',
      {},
      {
        auth: {
          username: payload.username,
          password: payload.password,
        },
      }
    );
  },

  // Verifies if given token is valid or not
  verifyToken: () => {
    return get('/token/verify');
  },

  // Verifies if given token can be refreshed
  refreshToken: (payload) => {
    return post('/token/refresh', payload);
  },

  // Encrypts password for user creation
  encryptPassword: (payload) => {
    return post('/encrypt', payload);
  },
};

export const { login, verifyToken, refreshToken, encryptPassword } = authAPI;
