// Core
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import OrdersToReceiveCard from './OrdersToReceiveCard';

// Dependencies
import { Card, Spinner, Row, Col, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Api
import { fetchOrdersToPickup } from '../../api/orders';

// Reducers
import { setOrdersToRetrieveFilters } from './orderSlice';

// Hooks
import useRefreshOrder from '../../hooks/useRefreshOrders';

const OrdersToRetrieve = ({ handleStateChangeModal }) => {
  const { ordersToRetrieveFilters: filters, ecommerces } = useSelector(
    (state) => state.orders
  );

  const loadMoreBottomRef = useRef(true);
  const loadMoreTopRef = useRef(true);

  const scrollRef = useRef(null);

  const [orders, setOrders] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);

  const dispatch = useDispatch();

  const [
    {
      isLoading,
      orders: ordersToRetrieve,
      totalPages,
      bottomInView,
      topInView,
    },
  ] = useRefreshOrder({
    apiCall: fetchOrdersToPickup,
    filters,
  });

  // Hooks runs whenever orders are fetched
  useEffect(() => {
    const updateOrders = async () => {
      const ordersCopy = [...ordersToRetrieve];

      if (!loadMoreTopRef.current) {
        // Enters this statement if user tried to load orders in the top side of the scroll
        ordersCopy[14].ref = scrollRef;
        await setOrders(ordersCopy);

        scrollRef.current.scrollIntoView();
        loadMoreTopRef.current = true;
      } else if (!loadMoreBottomRef.current) {
        // Enters this statement if user tried to load orders in the bottom side of the scroll
        ordersCopy[14].ref = scrollRef;
        await setOrders(ordersCopy);

        scrollRef.current.scrollIntoView();
        loadMoreBottomRef.current = true;
      } else {
        // Enters this if in the first statement or when other filters excluding "page" are set
        await setOrders(ordersCopy);
      }

      setOrdersLoading(false);
      setIsLoadingMore(false);
    };

    setOrdersLoading(true);
    if (!isLoading && ordersToRetrieve && ordersToRetrieve.length > 0) {
      updateOrders();
    } else if (
      !isLoading &&
      ordersToRetrieve &&
      ordersToRetrieve.length === 0
    ) {
      setOrders([]);
      setOrdersLoading(false);
    }
  }, [ordersToRetrieve, isLoading]);

  // If last card is inside the viewPort, calls hook above to fetch more orders
  useEffect(() => {
    const { page, ...rest } = filters;

    if (bottomInView && loadMoreBottomRef.current) {
      loadMoreBottomRef.current = false;

      setIsLoadingMore(true);
      dispatch(
        setOrdersToRetrieveFilters({
          page: filters.page + 1,
          ...rest,
        })
      );
    }
  }, [bottomInView, filters, totalPages, dispatch]);

  // If last card is inside the viewPort, calls hook above to fetch more orders
  useEffect(() => {
    const { page, ...rest } = filters;

    // Check if top card is in viewport and the loadMore ref is true
    if (topInView && loadMoreTopRef.current) {
      loadMoreTopRef.current = false;

      setIsLoadingMore(true);
      dispatch(
        setOrdersToRetrieveFilters({
          page: filters.page - 1,
          ...rest,
        })
      );
    }
  }, [topInView, filters, totalPages, dispatch]);

  // Toggle card info display
  const toggleShowMoreInfo = (payload) => {
    const ordersCopy = [...orders];

    ordersCopy[payload].showMore = ordersCopy[payload].showMore ? false : true;

    setOrders(ordersCopy);
  };

  const getEcommerceName = () => {
    if (filters.id_ecommerce) {
      return ecommerces.find(
        (ecommerce) => ecommerce.id_ecommerce === filters.id_ecommerce
      )['ecommerce_name'];
    }
    return '';
  };

  // Return text/spinner depending on load and result status
  const setPlaceholder = () => {
    let message = 'No se encontraron resultados a tu búsqueda';

    if (ordersLoading) {
      return (
        <Spinner
          animation="border"
          role="status"
          className="mx-auto text-primary"
        >
          <span className="sr-only">Cargando...</span>
        </Spinner>
      );
    }
    if (!filters.filter && !filters.id_ecommerce) {
      message = 'No tienes pedidos por entregar';
    }

    return (
      <Fragment>
        <Row className="w-100">
          <FontAwesomeIcon
            icon="grin-beam-sweat"
            className="text-secondary w-100 fa-4x mb-2"
          />
        </Row>
        <p className="m-auto">{message}</p>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col xs="auto p-0">
          <FormControl
            name="id_ecommerce"
            as="select"
            custom
            onChange={(e) => {
              const { id_ecommerce, ...rest } = filters;
              dispatch(
                setOrdersToRetrieveFilters({
                  id_ecommerce: e.target.value,
                  ...rest,
                })
              );
            }}
            value={filters.id_ecommerce}
            className="mb-2"
          >
            <option value="">Ecommerces</option>
            <option value={filters.id_ecommerce} hidden>
              {getEcommerceName()}
            </option>
            {ecommerces.map(({ id_ecommerce, ecommerce_name }) => (
              <option key={id_ecommerce} value={id_ecommerce}>
                {ecommerce_name}
              </option>
            ))}
          </FormControl>
        </Col>
      </Row>
      <Row className="cards-container">
        {isLoadingMore && !loadMoreTopRef.current && (
          <Spinner
            animation="border"
            role="status"
            className="mx-auto text-primary mt-5"
          >
            <span className="sr-only">Cargando...</span>
          </Spinner>
        )}

        {orders.length < 1 && (
          <Card className="order-card text-center py-3">
            {setPlaceholder()}
          </Card>
        )}

        {orders.map((order, index) => (
          <OrdersToReceiveCard
            order={order}
            key={order.order_code}
            index={index}
            toggleShowMoreInfo={toggleShowMoreInfo}
            handleStateChangeModal={handleStateChangeModal}
          />
        ))}

        {isLoadingMore && !loadMoreBottomRef.current && (
          <Spinner
            animation="border"
            role="status"
            className="mx-auto text-primary mt-5"
          >
            <span className="sr-only">Cargando...</span>
          </Spinner>
        )}
      </Row>
    </Fragment>
  );
};

export default OrdersToRetrieve;
