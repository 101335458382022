// Core
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Dependencies
import { InputGroup, FormControl, Button, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Reducers
import {
  setOrdersToDeliverFilters,
  setOrdersToRetrieveFilters,
} from './orderSlice';
import { setMessage } from '../toasts/toastSlice';

// Stylesheets
import './orders.scss';

const SearchBar = ({ placeholder }) => {
  const { ordersToRetrieveFilters, ordersToDeliverFilters } = useSelector(
    (state) => state.orders
  );
  const [temporalFilter, setTemporalFilter] = useState('');

  const dispatch = useDispatch();

  // Update filters
  const handleSubmit = () => {
    if (temporalFilter.length > 200) {
      dispatch(
        setMessage({
          body: 'El filtro no puede superar los 200 caracteres',
          type: 'error',
        })
      );
    } else {
      const {
        page: toRetrievePage,
        filter: toRetrieveFilter,
        ...toRetrieveFilters
      } = ordersToRetrieveFilters;
      const {
        page: toDeliverPage,
        filter: toDeliverFilter,
        ...toDeliverFilters
      } = ordersToDeliverFilters;

      // Will refresh the tab in dashboard that is currently active
      dispatch(
        setOrdersToDeliverFilters({
          filter: temporalFilter,
          page: 1,
          ...toDeliverFilters,
        })
      );
      dispatch(
        setOrdersToRetrieveFilters({
          filter: temporalFilter,
          page: 1,
          ...toRetrieveFilters,
        })
      );
    }
  };

  return (
    <Row className="search-bar mt-3">
      <InputGroup>
        <FormControl
          placeholder={placeholder}
          value={temporalFilter}
          aria-label={placeholder}
          onChange={(e) => setTemporalFilter(e.target.value)}
          className="search-bar__input text-truncate"
        />
        <InputGroup.Append>
          <Button onClick={handleSubmit} className="search-bar__input-button">
            <FontAwesomeIcon icon="search" className="fa-lg" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Row>
  );
};

export default SearchBar;
