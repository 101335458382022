export const orderStatuses = {
  TO_PICKUP_FROM_ECOMMERCE: 1, // Por retirar desde Ecommerce
  TO_RECEIVE_FROM_ECOMMERCE: 2, // Por recibir de ecommerce
  TO_ASSEMBLE: 3, // Por armar
  IN_ROUTE_TO_PICKUP_POINT: 4, // En camino a Pickup Point
  ASSEMBLED: 5, // Armado
  READY_TO_BE_DELIVERED: 6, // Listo para entregar
  IN_ROUTE_TO_CONSUMER: 7, // En camino a consumidor
  DELIVERED_TO_CONSUMER: 8, // Entregado a consumidor
  TO_RETURN_TO_WARECLOUD: 9, // Por devolver a Warecloud
  TO_PICKUP_FROM_CONSUMER: 10, // Por retirar desde Consumidor
  IN_ROUTE_TO_WARECLOUD: 11, // En camino a Warecloud
  TO_RETURN_TO_ECOMMERCE: 12, // Por devolver a Ecommerce
  RETURNED_TO_ECOMMERCE: 13, // Devuelto a Ecommerce
  RECEIVED: 14, // Recibido
  TO_DISASSEMBLE: 15, // Por desarmar
  DISASSEMBLED: 16, // Desarmado
  CANCELLED: 99,
};
