import Cookies from 'universal-cookie';

// New cookies instance
const cookies = new Cookies();

const tokenHandling = {
  saveAccessToken: (payload) => {
    // The cookie expires after 1 hour
    cookies.set('accessToken', payload, { path: '/', maxAge: 3600 });
  },
  saveRefreshToken: (payload) => {
    localStorage.setItem('refreshToken', payload);
  },
  retrieveToken: () => {
    return cookies.get('accessToken');
  },
  retrieveRefreshToken: () => {
    return localStorage.getItem('refreshToken');
  },
  removeToken: () => {
    localStorage.clear();
    cookies.remove('accessToken');
  },
};

export const {
  saveAccessToken,
  saveRefreshToken,
  retrieveToken,
  retrieveRefreshToken,
  removeToken,
} = tokenHandling;
