// Set up FA icons library
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faExclamationTriangle,
  faCheckCircle,
  faSearch,
  faChevronDown,
  faChevronUp,
  faClock,
  faSmileWink,
  faGrinBeamSweat,
} from '@fortawesome/free-solid-svg-icons';

const populateIcons = () =>
  library.add(
    faUser,
    faExclamationTriangle,
    faCheckCircle,
    faSearch,
    faChevronDown,
    faChevronUp,
    faClock,
    faSmileWink,
    faGrinBeamSweat
  );

export default populateIcons;
