// Core
import React from 'react';

// Dependencies
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

// Components
import LoginForm from './LoginForm';

// Assets
import logo from '../../assets/img/logo.svg';

// Stylesheets
import './login.scss';

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  height: 100vh;

  //IPHONE&SAMSUNG PLUS
  @media (max-height: 736px) {
    max-height: 736px;
  }
  //IPHONE 6/7/8
  @media (max-height: 667px) {
    max-height: 667px;
  }
  //IPHONE %
  @media (max-height: 568px) {
    max-height: 568px;
  }
`;

// Layout component for login
const LoginPage = () => {
  return (
    <LoginContainer>
      <Col xs={12} md={10} lg={8} className="d-flex">
        <Row className="login mx-auto">
          <div className="mb-5 text-center">
            <img src={logo} alt="wareclouds-logo" className="login__logo" />
          </div>
          <p className="login__title">Iniciar sesión</p>
          <LoginForm />
        </Row>
      </Col>
    </LoginContainer>
  );
};

export default LoginPage;
