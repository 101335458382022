import axios from 'axios';

import { retrieveToken } from '../utils/token';

// Helper that returns the authorization header with the available token
export const authorization = (extra) => {
  const token = retrieveToken();

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...extra,
  };
};

// Customized Error for Axios requests
class RequestError extends Error {
  constructor(message, data) {
    super(message);
    this.name = 'RequestError';
    this.data = data;
  }
}

// Creates the instance of an axios object with variant baseURL
const createAuthorizedAPI = (payload) => {
  const api = axios.create({
    baseURL: payload,
    //60 sec timeout
    timeout: 60000,

    //follow up to 10 HTTP 3xx redirects
    maxRedirects: 10,

    //cap the maximum content length we'll accept to 50MBs, just in case
    maxContentLength: 50 * 1000 * 1000,
  });

  // fix data attribute nesting
  api.interceptors.response.use(
    (response) => {
      if (response.data.error) {
        throw new RequestError('Not found', response.data.data);
      } else {
        return response.data;
      }
    },
    (error) => {
      if (error.response.data.data === 'Unauthorized') {
        // Enters the first if when accessToken has expired
        window.location.reload();
        throw new RequestError(error.message, 'Refrescando permisos...');
      } else if (error.response.data === 'Unauthorized') {
        // Enters when error is related to user authorization restrictions
        throw new RequestError(error.message, error.response.data);
      } else {
        // Enters when errors are related to invalid data/request bodies
        throw new RequestError(error.message, error.response.data.data);
      }
    }
  );

  // Customized axios request methods
  const get = (endpoint) => api.get(endpoint, authorization());

  const loginPost = (endpoint, payload, options) =>
    api.post(endpoint, payload, options);

  const post = (endpoint, payload) =>
    api.post(endpoint, payload, authorization());

  const put = (endpoint, payload) =>
    api.put(endpoint, payload, authorization());

  const remove = (endpoint, payload) =>
    api.delete(endpoint, authorization({ data: payload }));

  return { get, post, put, remove, loginPost };
};

export default createAuthorizedAPI;
