import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ordersToRetrieveFilters: { filter: '', id_ecommerce: '', page: 1 },
  ordersToDeliverFilters: { filter: '', id_warecloud: '', page: 1 },
  ecommerces: [],
  wareclouds: [],
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrdersToRetrieveFilters(state, action) {
      state.ordersToRetrieveFilters = action.payload;
    },
    setOrdersToDeliverFilters(state, action) {
      state.ordersToDeliverFilters = action.payload;
    },
    setEcommerces(state, action) {
      state.ecommerces = action.payload;
    },
    setWareclouds(state, action) {
      state.wareclouds = action.payload;
    },
  },
});

export const {
  setOrdersToRetrieveFilters,
  setOrdersToDeliverFilters,
  setEcommerces,
  setWareclouds,
} = orderSlice.actions;

export default orderSlice.reducer;
