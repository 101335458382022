import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthorized: false,
  username: '',
};

const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthorized(state, action) {
      state.isAuthorized = action.payload;
    },
    setUsername(state, action) {
      state.username = action.payload;
    },
    // Reducer taken care of in the root reducer to reset whole state
    logout: (state) => {},
  },
});

export const { logout, setIsAuthorized, setUsername } = loginSlice.actions;

export default loginSlice.reducer;
