// Core
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Dependencies
import { Modal, Form, Row } from 'react-bootstrap';

// Components
import ButtonWithLoader from '../common/ButtonWithLoader';

// API
import {
  confirmDeliveryToWarecloud,
  confirmPickupFromEcommerce,
} from '../../api/orders';

// Reducers
import { setMessage } from '../toasts/toastSlice';

import {
  setOrdersToDeliverFilters,
  setOrdersToRetrieveFilters,
} from './orderSlice';

// Consts
import { orderStatuses } from '../../config/consts/orders';

const OrderStateChangeModal = ({ show, handleClose, title, order }) => {
  const { ordersToRetrieveFilters, ordersToDeliverFilters } = useSelector(
    (state) => state.orders
  );

  const { TO_PICKUP_FROM_ECOMMERCE, IN_ROUTE_TO_PICKUP_POINT } = orderStatuses;
  const [comment, setComment] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  // Return the appropiate api CALL
  const orderStateApiCall = () => {
    const { id_status_order: state } = order;
    if (state === TO_PICKUP_FROM_ECOMMERCE) {
      return confirmPickupFromEcommerce;
    }
    if (state === IN_ROUTE_TO_PICKUP_POINT) {
      return confirmDeliveryToWarecloud;
    }
  };

  // Refreshes the corresponding section, saving the ref index where order was at
  const refreshOrders = () => {
    const { id_status_order: state } = order;
    if (state === TO_PICKUP_FROM_ECOMMERCE) {
      dispatch(setOrdersToRetrieveFilters({ ...ordersToRetrieveFilters }));
    } else if (state === IN_ROUTE_TO_PICKUP_POINT) {
      dispatch(setOrdersToDeliverFilters({ ...ordersToDeliverFilters }));
    }
  };
  // Handle confirmation depending on order status
  const handleSubmit = async () => {
    const { id_order, id_status_order, order_code } = order;

    setIsSubmitting(true);
    try {
      const payload = { id_order, comment };

      await orderStateApiCall()(payload);

      setIsSubmitting(false);
      handleClose();

      refreshOrders();

      let message;

      if (id_status_order === TO_PICKUP_FROM_ECOMMERCE) {
        message = `Se ha retirado el pedido ${order_code} exitosamente`;
      } else {
        message = `Se ha entregado el pedido ${order_code} exitosamente`;
      }

      dispatch(
        setMessage({
          body: message,
          type: 'success',
        })
      );
    } catch (err) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          body: `${err.data}`,
          type: 'error',
        })
      );
    }
  };

  return (
    <Modal
      aria-labelledby="state-change-modal"
      className="state-change-modal"
      centered
      show={show}
      onHide={handleClose}
      animation={false}
      backdrop="static"
    >
      <Modal.Header closeButton />
      <Modal.Body className="mx-3">
        <Row className="my-2">{title}</Row>
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Form.Row className="my-3">
            <Form.Label>Comentarios</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Row>
          <Form.Row className="justify-content-center">
            <ButtonWithLoader
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              defaultLabel="Confirmar"
              isSubmittingLabel="Procesando"
            />
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default OrderStateChangeModal;
