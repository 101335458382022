// Core
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import OrdersToDeliverCard from './OrdersToDeliverCard';

// Dependencies
import { Card, Spinner, Row, Col, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Api
import { fetchOrdersInRoute } from '../../api/orders';

// Reducers
import { setOrdersToDeliverFilters } from './orderSlice';

// Hooks
import useRefreshOrder from '../../hooks/useRefreshOrders';

const OrdersToDeliver = ({ handleStateChangeModal }) => {
  const { ordersToDeliverFilters: filters, wareclouds } = useSelector(
    (state) => state.orders
  );

  const loadMoreBottomRef = useRef(true);
  const loadMoreTopRef = useRef(true);

  const scrollRef = useRef(null);

  const [orders, setOrders] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);

  const dispatch = useDispatch();

  const [
    { isLoading, orders: ordersToDeliver, totalPages, bottomInView, topInView },
  ] = useRefreshOrder({
    apiCall: fetchOrdersInRoute,
    filters,
  });

  // Hooks runs whenever orders are fetched
  useEffect(() => {
    const updateOrders = async () => {
      const ordersCopy = [...ordersToDeliver];

      if (!loadMoreTopRef.current) {
        // Enters this statement if user tried to load orders in the top side of the scroll
        ordersCopy[14].ref = scrollRef;
        await setOrders(ordersCopy);

        scrollRef.current.scrollIntoView();
        loadMoreTopRef.current = true;
      } else if (!loadMoreBottomRef.current) {
        // Enters this statement if user tried to load orders in the bottom side of the scroll
        ordersCopy[14].ref = scrollRef;
        await setOrders(ordersCopy);

        scrollRef.current.scrollIntoView();
        loadMoreBottomRef.current = true;
      } else {
        // Enters this if in the first statement or when other filters excluding "page" are set
        await setOrders(ordersCopy);
      }

      setOrdersLoading(false);
      setIsLoadingMore(false);
    };

    setOrdersLoading(true);
    if (!isLoading && ordersToDeliver && ordersToDeliver.length > 0) {
      updateOrders();
    } else if (!isLoading && ordersToDeliver && ordersToDeliver.length === 0) {
      setOrders([]);
      setOrdersLoading(false);
    }
  }, [ordersToDeliver, isLoading]);

  // If last card is inside the viewPort, calls hook above to fetch more orders
  useEffect(() => {
    const { page, ...rest } = filters;

    if (bottomInView && loadMoreBottomRef.current) {
      loadMoreBottomRef.current = false;

      setIsLoadingMore(true);
      dispatch(
        setOrdersToDeliverFilters({
          page: filters.page + 1,
          ...rest,
        })
      );
    }
  }, [bottomInView, filters, totalPages, dispatch]);

  // If last card is inside the viewPort, calls hook above to fetch more orders
  useEffect(() => {
    const { page, ...rest } = filters;

    // Check if top card is in viewport and the loadMore ref is true
    if (topInView && loadMoreTopRef.current) {
      loadMoreTopRef.current = false;

      setIsLoadingMore(true);
      dispatch(
        setOrdersToDeliverFilters({
          page: filters.page - 1,
          ...rest,
        })
      );
    }
  }, [topInView, filters, totalPages, dispatch]);

  // Toggle card info display
  const toggleShowMoreInfo = (payload) => {
    const ordersCopy = [...orders];

    ordersCopy[payload].showMore = ordersCopy[payload].showMore ? false : true;

    setOrders(ordersCopy);
  };

  // Helpter to retrieve name of select value for wareclouds
  const getWarecloudName = () => {
    if (filters.id_warecloud) {
      const warecloud = wareclouds.find(
        (warecloud) => warecloud.id_warecloud === filters.id_warecloud
      );

      return `${warecloud.warecloud_first_name} ${warecloud.warecloud_last_name}`;
    }
    return '';
  };

  // Return text/spinner depending on load and result status
  const setPlaceholder = () => {
    let message = 'No se encontraron resultados a tu búsqueda';

    if (ordersLoading) {
      return (
        <Spinner
          animation="border"
          role="status"
          className="mx-auto text-primary"
        >
          <span className="sr-only">Cargando...</span>
        </Spinner>
      );
    }
    if (!filters.filter && !filters.id_warecloud) {
      message = 'No tienes pedidos por entregar';
    }

    return (
      <Fragment>
        <Row className="w-100">
          <FontAwesomeIcon
            icon="grin-beam-sweat"
            className="text-secondary w-100 fa-4x mb-2"
          />
        </Row>
        <p className="m-auto">{message}</p>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col xs="auto p-0">
          <FormControl
            name="id_clouder"
            as="select"
            custom
            onChange={(e) => {
              const { id_warecloud, ...rest } = filters;
              dispatch(
                setOrdersToDeliverFilters({
                  id_warecloud: e.target.value,
                  ...rest,
                })
              );
            }}
            value={filters.id_warecloud}
            className="mb-2"
          >
            <option value="">Wareclouds</option>
            <option value={filters.id_warecloud} hidden>
              {getWarecloudName()}
            </option>
            {wareclouds.map(
              ({ id_warecloud, warecloud_first_name, warecloud_last_name }) => (
                <option key={id_warecloud} value={id_warecloud}>
                  {`${warecloud_first_name} ${warecloud_last_name}`}
                </option>
              )
            )}
          </FormControl>
        </Col>
      </Row>
      <Row className="cards-container">
        {isLoadingMore && !loadMoreTopRef.current && (
          <Spinner
            animation="border"
            role="status"
            className="mx-auto text-primary mt-5"
          >
            <span className="sr-only">Cargando...</span>
          </Spinner>
        )}

        {orders.length < 1 && (
          <Card className="order-card text-center py-3">
            {setPlaceholder()}
          </Card>
        )}

        {orders.map((order, index) => (
          <OrdersToDeliverCard
            order={order}
            index={index}
            key={order.order_code}
            toggleShowMoreInfo={toggleShowMoreInfo}
            handleStateChangeModal={handleStateChangeModal}
          />
        ))}
        {isLoadingMore && !loadMoreBottomRef.current && (
          <Spinner
            animation="border"
            role="status"
            className="mx-auto text-primary mt-5"
          >
            <span className="sr-only">Cargando...</span>
          </Spinner>
        )}
      </Row>
    </Fragment>
  );
};

export default OrdersToDeliver;
