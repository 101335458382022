import createAuthorizedAPI from './index';

const cloudersURL = process.env.REACT_APP_CLOUDER_PICKUP_API_URL;

const { get } = createAuthorizedAPI(cloudersURL);

const miscAPI = {
  // Returns an array of ecommerces assigned to a clouderPickup
  getAssignedEcommerces: () => {
    return get(`/clouderPickups/ecommerces`);
  },
  // Return an array of associated wareclouds to deliver orders
  getAssignedWareclouds: () => {
    return get(`/clouderPickups/wareclouds`);
  },
};

export const { getAssignedWareclouds, getAssignedEcommerces } = miscAPI;
