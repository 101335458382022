import { combineReducers } from 'redux';
import toastReducer from '../features/toasts/toastSlice';
import loginReducer from '../features/login/loginSlice';
import orderReducer from '../features/orders/orderSlice';

const combinedReducers = combineReducers({
  auth: loginReducer,
  orders: orderReducer,
  toast: toastReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default rootReducer;
