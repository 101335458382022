// Core
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Dependencies
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Button, Spinner } from 'react-bootstrap';

// Stylesheet
import './login.scss';

// API & tokens
import { login } from '../../api/auth';
import { saveAccessToken, saveRefreshToken } from '../../utils/token';

const signInSchema = Yup.object().shape({
  username: Yup.string().required('Este campo no puede quedar vacío'),

  password: Yup.string().required('Este campo no puede quedar vacío'),
});

const LoginForm = () => {
  const history = useHistory();
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    //Sets initial values for form inputs
    <Formik
      initialValues={{ username: '', password: '' }}
      validationSchema={signInSchema}
      onSubmit={async ({ username, password }) => {
        setIsLoading(true);

        try {
          const response = await login({ username, password });
          const { accessToken, refreshToken } = response.data;

          // Saves generated token and username
          await saveAccessToken(accessToken);
          await saveRefreshToken(refreshToken);

          history.go(0);
        } catch (err) {
          setIsLoading(false);

          if (err.data === 'Unauthorized') {
            setLoginError(
              'El usuario y/o contraseña ingresados son incorrectos'
            );
          } else {
            setLoginError('Ha ocurrido un error, inténtalo más tarde');
          }
        }
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit} className="mt-4">
          <Form.Group className="position-relative mb-0">
            <FontAwesomeIcon icon="user" className="login__icon" />

            <Form.Control
              className="login__input login__input--rounded-top"
              type="text"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              placeholder="Usuario"
              aria-describedby="basic-addon1"
            />
          </Form.Group>
          <Form.Group className="position-relative mb-0">
            <FontAwesomeIcon icon={faUnlockAlt} className="login__icon" />

            <Form.Control
              className="login__input login__input--rounded-bottom"
              type="password"
              name="password"
              placeholder="Contraseña"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            {loginError && (
              <p className="login__error text-danger">{loginError}</p>
            )}
          </Form.Group>

          <Form.Row className="mt-1 mb-3">
            <Button
              className="login__button"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {isLoading ? 'Procesando...' : 'Iniciar sesión'}
            </Button>
          </Form.Row>

          {/* <Form.Row>
            <p className="login__text login__text--dark-grey">
              ¿Olvidaste tu contraseña?
            </p>
          </Form.Row> */}
          <Form.Row>
            <p className="login__text">
              ¿No puedes iniciar sesión?
              <span
                className="login__text--dark-grey ml-2"
                onClick={() =>
                  (window.location.href = 'mailto:hello@wareclouds.com')
                }
              >
                Contáctanos
              </span>
            </p>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
