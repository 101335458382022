// Core
import React from 'react';

// Styles
import { Row, Col } from 'react-bootstrap';

// Wrapper component for parent layout components
const MainLayoutWrapper = ({ children }) => {
  return (
    <Row className="justify-content-center">
      <Col xs={12} md={8} lg={6} xl={4} className="d-flex flex-column ">
        {children}
      </Col>
    </Row>
  );
};

export default MainLayoutWrapper;
