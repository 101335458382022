// Core
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import LogoutModal from './LogoutModal';

// Assets
import logo from '../../../assets/img/inside-logo.svg';

// Dependencies
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

const NavigationBar = () => {
  const { username } = useSelector((state) => state.auth);

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <Fragment>
      <LogoutModal
        show={showLogoutModal}
        handleClose={() => setShowLogoutModal(false)}
      />

      <Navbar bg="primary" expand="lg" collapseOnSelect>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            height="25"
            className="d-inline-block align-top"
            alt="Wareclouds logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="wareclouds-nav" />
        <Navbar.Collapse id="wareclouds-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/dashboard" className="text-white">
              Dashboard
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <Navbar.Collapse className="justify-content-end">
          <Nav className="flex-row">
            <NavDropdown
              id="user-menu-dropdown"
              alignRight
              title={username}
              className="w-100"
            >
              <NavDropdown.Item onClick={() => setShowLogoutModal(true)}>
                Cerrar sesión
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};

export default NavigationBar;
