// Core
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Login from '../features/login/';

// Custom hook
import useTokenRefresh from '../hooks/useRefreshToken';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { module } = rest;
  const { isAuthorized } = useSelector((state) => state.auth);
  const [isLoading] = useTokenRefresh(module);

  if (isLoading) {
    return null;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuthorized && module !== 'login') {
            // If user is not authorized and is in a different route than login
            return <Redirect to={'/login'} />;
          } else if (!isAuthorized) {
            // If user is at the login module
            return <Login {...props} />;
          } else if (module === 'login' && isAuthorized) {
            // If user tries to go to login module even though they are authorized
            return (
              <Redirect
                to={{ pathname: '/dashboard', state: { from: props.location } }}
              />
            );
          } else {
            // Render normally
            return <Component {...props} />;
          }
        }}
      />
    );
  }
};

export default PrivateRoute;
