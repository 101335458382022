// Core
import React from 'react';

// Dependencies
import { Button, Card, Row, Col, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utils
import dateParser from '../../utils/dateParser';

// Consts
import { orderStatuses } from '../../config/consts/orders';

const OrdersToReceiveCard = ({
  order,
  index,
  toggleShowMoreInfo,
  handleStateChangeModal,
}) => {
  const {
    id_order,
    order_code,
    ecommerce_phone,
    ecommerce_email,
    ecommerce_logo,
    ecommerce_name,
    ecommerce_address,
    ecommerce_city_name,
    ecommerce_manager_first_name,
    ecommerce_manager_last_name,
    updated_at,
    ref,
    showMore,
  } = order;

  const { TO_PICKUP_FROM_ECOMMERCE } = orderStatuses;

  return (
    <Card className="order-card" ref={ref}>
      <Card.Body className="py-3 px-4">
        <p className="order-card__title"># {order_code}</p>

        <Row className="my-4">
          <Col xs="auto" className="order-card__logo">
            {ecommerce_logo ? (
              <img
                src={ecommerce_logo}
                alt="ecommerce-logo"
                className="img-fluid"
              />
            ) : (
              <FontAwesomeIcon
                icon="smile-wink"
                className="order-card__logo-placeholder"
              />
            )}
          </Col>
          <Col className="my-auto">
            <p className="order-card__label order-card__label--large">Tienda</p>
            <p className="order-card__text order-card__text--large">
              {ecommerce_name}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="p-0 my-2 pr-2">
            <p className="order-card__label">Dirección de retiro</p>
            <p className="order-card__text">{ecommerce_address}</p>
          </Col>
          <Col xs={12} md="auto" className="p-0  my-2">
            <p className="order-card__label">Comuna</p>
            <p className="order-card__text">{ecommerce_city_name}</p>
          </Col>
        </Row>
        <Collapse in={showMore}>
          <Row>
            <Col xs={12} md={6} className="p-0 my-2 pr-2">
              <p className="order-card__label">Manager de tienda</p>
              <p className="order-card__text">
                {ecommerce_manager_first_name} {ecommerce_manager_last_name}
              </p>
            </Col>
            <Col xs={12} md={6} className="p-0 my-2">
              <p className="order-card__label">Teléfono manager</p>
              <a
                href={`tel:${ecommerce_phone}`}
                className="order-card__text order-card__text--phone"
              >
                {ecommerce_phone}
              </a>
            </Col>
            <Col xs={12} md={6} className="p-0 my-2">
              <p className="order-card__label">Fecha ingreso por recibir</p>
              <p className="order-card__text">{dateParser(updated_at)}</p>
            </Col>
            <Col xs={12} md={6} className="p-0 my-2">
              <p className="order-card__label">Email manager</p>
              <p className="order-card__text">{ecommerce_email}</p>
            </Col>
          </Row>
        </Collapse>

        <Row className="justify-content-around my-3">
          <Button
            className="order-card__return-button"
            onClick={() => {
              toggleShowMoreInfo(index);
            }}
          >
            <FontAwesomeIcon icon={showMore ? 'chevron-up' : 'chevron-down'} />
          </Button>

          <Button
            className="order-card__button"
            onClick={() => {
              const payload = {
                title: (
                  <h3 className="text-center">
                    ¿Confirmas la recepción del pedido{' '}
                    <span className="font-weight-bold text-primary">
                      {order_code}
                    </span>{' '}
                    de la tienda{' '}
                    <span className="font-weight-bold text-primary">
                      {ecommerce_name}{' '}
                    </span>
                    ?
                  </h3>
                ),
                order: {
                  id_order,
                  id_status_order: TO_PICKUP_FROM_ECOMMERCE,
                  order_code,
                },
              };
              handleStateChangeModal(payload);
            }}
          >
            Recibir pedido
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default OrdersToReceiveCard;
