// Core
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';

// Reducers
import { setMessage } from '../features/toasts/toastSlice';

const useRefreshOrder = ({ apiCall, filters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  // Sets ref using Intersection Observer API
  const { ref: bottomRef, inView: bottomInView } = useInView({
    /* Toggle when last card is at 0.5 inside viewport */
    threshold: 0.5,
  });

  // Sets ref using Intersection Observer API
  const { ref: topRef, inView: topInView } = useInView({
    /* Toggle when last card is at 0.5 inside viewport */
    threshold: 0.5,
  });

  useEffect(() => {
    const sendRequest = async () => {
      setIsLoading(true);

      try {
        const response = await apiCall(filters);

        const { totalPages, currentPage, orders } = response.data;

        let newOrders;
        const { page, ...rest } = filters;

        if (currentPage > 1) {
          // Add to orders array the orders that are from the page before
          const beforePageResponse = await apiCall({ page: page - 1, ...rest });

          const { orders: beforePageOrders } = beforePageResponse.data;

          newOrders = [...beforePageOrders, ...orders];

          // Add ref to the last item in the array only if current page
          // is less than totalPages
          if (page < totalPages) {
            newOrders[newOrders.length - 1].ref = bottomRef;
          }
        } else {
          // Enters this statement when at first page
          newOrders = orders;

          // Add ref to the  item in the array only if current page
          // is less than totalPages
          if (newOrders.length > 0 && page < totalPages) {
            newOrders[newOrders.length - 1].ref = bottomRef;
          }
        }

        // Add ref to the first item in the array only if current page
        // is greather than 2 so that the ref is never in page 1
        if (newOrders.length > 0 && page > 2) {
          newOrders[0].ref = topRef;
        }

        // Add showMore attribute to all orders to toggle details display
        newOrders = newOrders.map((order) => {
          order.showMore = false;

          return order;
        });

        // Set orders and total pages
        setOrders(newOrders);
        setTotalPages(totalPages);

        setCurrentPage(currentPage);
      } catch (err) {
        dispatch(
          setMessage({
            body: `${err.data}`,
            type: 'error',
          })
        );
      }

      setIsLoading(false);
    };

    sendRequest();
  }, [apiCall, dispatch, filters, bottomRef, topRef]);

  return [
    { isLoading, orders, currentPage, totalPages, bottomInView, topInView },
  ];
};

export default useRefreshOrder;
