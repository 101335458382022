// Helper function that returns a date string to the yyyy-mm-dd hh:mm format
const dateParser = (date) => {
  const newDate = new Date(date);
  return (
    newDate.getFullYear() +
    '-' +
    (newDate.getMonth() + 1) +
    '-' +
    newDate.getDate() +
    ' ' +
    newDate.getHours() +
    ':' +
    `${
      newDate.getMinutes() < 10
        ? `0${newDate.getMinutes()}`
        : newDate.getMinutes()
    }` +
    ' hrs.'
  );
};

export default dateParser;
