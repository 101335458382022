// Core
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Dependencies
import { Modal, Button } from 'react-bootstrap';

// Reducers
import { logout } from '../../login/loginSlice';

// API & tokens
import { removeToken } from '../../../utils/token';

const LogoutModal = ({ show, handleClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    removeToken();
    handleClose();
    dispatch(logout());
    history.push('/login');
  };

  return (
    <Modal
      aria-labelledby="logout-modal"
      centered
      show={show}
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="logout-modal">Cerrar sesión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Estás seguro que deseas cerrar tu sesión?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleLogout}>
          Cerrar sesión
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
