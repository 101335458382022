// Core
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Dependencies
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

// Styles
import 'react-toastify/dist/ReactToastify.css';

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast {
    margin: 0;
    padding: 0;
  }
  .Toastify__close-button {
    color: grey;
  }
  .Toastify__toast--error {
    color: black;
    background-color: whitesmoke;
  }
  .Toastify__toast--success {
    color: black;
    background-color: whitesmoke;
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }
  .Toastify__progress-bar {
    background-color: #5138ef;
  }
  .Toastify__progress-bar--success {
    background-color: #28a745;
  }
  .Toastify__progress-bar--error {
    background-color: #dc3545;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1em 1em 1em 2.5em;
  justify-content: center;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: -2em;
`;

const CustomMessage = ({ message, type }) => {
  return (
    <MessageContainer>
      <div className="position-relative">
        <p className="m-0 py-0">
          <strong>{type}</strong>
        </p>
        <IconContainer>
          <FontAwesomeIcon
            icon={type === 'Éxito' ? 'check-circle' : 'exclamation-triangle'}
            className={`${
              type === 'Éxito' ? 'text-success' : 'text-danger'
            } fa-lg`}
          />
        </IconContainer>
      </div>
      <div>
        <p className="m-0 py-0">{message}</p>
      </div>
    </MessageContainer>
  );
};

const CustomToast = () => {
  const message = useSelector((state) => state.toast.message);
  const [t] = useTranslation();

  // Shoots a toast whenever message state is updated
  useEffect(() => {
    const { body: code, type } = message;

    const translatedMessage = () => {
      const exp = /\[([^\]]+)]/;

      // Check if message displayed should include a
      // specific attribute
      const givenField = code.match(exp);

      if (givenField) {
        const isolatedCode = code.substr(code.indexOf(' ') + 1);
        return `El parámetro "${givenField[1]}" ${t(isolatedCode)}`;
      }

      return t(code);
    };

    if (type === 'success') {
      toast.success(
        <CustomMessage message={translatedMessage()} type="Éxito" />
      );
    } else if (type === 'error') {
      toast.error(<CustomMessage message={translatedMessage()} type="Error" />);
    }
  }, [message, t]);

  return (
    <StyledContainer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
    </StyledContainer>
  );
};

export default CustomToast;
