//Core
import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Routes
import Routes from './routes/';

// Navigation bar
import NavigationBar from './features/common/_navbar/NavigationBar';

// Toasts
import Toast from './features/toasts';

// FA icons
import populateIcons from './assets/icons/';
populateIcons();

function App() {
  const { isAuthorized } = useSelector((state) => state.auth);

  return (
    <Router>
      {isAuthorized && (
        <Fragment>
          <NavigationBar />
          <Toast />
        </Fragment>
      )}

      <Routes />
    </Router>
  );
}

export default App;
