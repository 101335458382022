// Core
import React from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

// Layouts
import OrdersPage from '../features/orders';

// Not found page
import NotFoundPage from '../features/common/NotFoundPage';

// Token
import { removeToken } from '../utils/token';

// // Custom authorized route middleware
import PrivateRoute from './PrivateRoute';

// Reducers
import { setIsAuthorized } from '../features/login/loginSlice';

// Encompases all routes inside an authorized e-commerce user's domain
const Routes = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnIdle = () => {
    dispatch(setIsAuthorized(false));
    removeToken();
    history.push('/login');
  };

  // Timer that logs the user out after 30 minutes of inactivity
  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <Switch>
      <PrivateRoute path="/" exact module="index" component={OrdersPage} />
      <PrivateRoute
        path="/dashboard"
        exact
        module="dashboard"
        component={OrdersPage}
      />

      <PrivateRoute path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
