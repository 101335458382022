// Core
import React from 'react';

// Dependencies
import { Button, Card, Row, Col, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utils
import dateParser from '../../utils/dateParser';

// Consts
import { orderStatuses } from '../../config/consts/orders';

const OrdersToDeliverCard = ({
  order,
  index,
  toggleShowMoreInfo,
  handleStateChangeModal,
}) => {
  const {
    id_order,
    id_order_transfer_confirmation,
    order_code,
    ecommerce_logo,
    ecommerce_name,
    warecloud_name,
    warecloud_address,
    warecloud_phone,
    warecloud_city,
    ref,
    updated_at,
    showMore,
  } = order;

  const { IN_ROUTE_TO_PICKUP_POINT } = orderStatuses;

  return (
    <Card className="order-card" ref={ref}>
      <Card.Body className="py-3 px-4">
        <p className="order-card__title"># {order_code}</p>
        <Row className="my-4">
          <Col xs="auto" className="order-card__logo">
            {ecommerce_logo ? (
              <img
                src={ecommerce_logo}
                alt="ecommerce-logo"
                className="img-fluid"
              />
            ) : (
              <FontAwesomeIcon
                icon="smile-wink"
                className="order-card__logo-placeholder"
              />
            )}
          </Col>
          <Col className="my-auto">
            <p className="order-card__label order-card__label--large">
              Warecloud
            </p>
            <p className="order-card__text order-card__text--large">
              {warecloud_name}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="p-0 my-2 pr-2">
            <p className="order-card__label">Dirección de entrega</p>
            <p className="order-card__text">{warecloud_address}</p>
          </Col>
          <Col xs={12} md="auto" className="p-0  my-2">
            <p className="order-card__label">Comuna</p>
            <p className="order-card__text">{warecloud_city}</p>
          </Col>
        </Row>
        <Collapse in={showMore}>
          <Row>
            <Col xs={12} md={6} className="p-0 my-2">
              <p className="order-card__label">Teléfono Warecloud</p>
              <a
                href={`tel:${warecloud_phone}`}
                className="order-card__text order-card__text--phone"
              >
                {warecloud_phone}
              </a>
            </Col>
            <Col xs={12} md={6} className="p-0 my-2">
              <p className="order-card__label">Tienda</p>
              <p className="order-card__text">{ecommerce_name}</p>
            </Col>

            <Col xs={12} md={6} className="p-0 my-2">
              <p className="order-card__label">
                Fecha ingreso en camino a Pickup Point
              </p>
              <p className="order-card__text">{dateParser(updated_at)}</p>
            </Col>
          </Row>
        </Collapse>

        <Row className="justify-content-around my-3">
          <Button
            className="order-card__return-button"
            onClick={() => {
              toggleShowMoreInfo(index);
            }}
          >
            <FontAwesomeIcon icon={showMore ? 'chevron-up' : 'chevron-down'} />
          </Button>
          {id_order_transfer_confirmation ? (
            <Button variant="warning" disabled>
              Confirmación pendiente{' '}
              <FontAwesomeIcon className="text-secondary" icon="clock" />
            </Button>
          ) : (
            <Button
              className="order-card__button"
              onClick={() => {
                const payload = {
                  title: (
                    <h3 className="text-center">
                      ¿Confirmas la entrega del pedido{' '}
                      <span className="font-weight-bold text-primary">
                        {order_code}
                      </span>{' '}
                      al warecloud{' '}
                      <span className="font-weight-bold text-primary">
                        {warecloud_name}{' '}
                      </span>
                      ?
                    </h3>
                  ),
                  order: {
                    id_order,
                    id_status_order: IN_ROUTE_TO_PICKUP_POINT,
                    order_code,
                  },
                };
                handleStateChangeModal(payload);
              }}
            >
              Entregar pedido
            </Button>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default OrdersToDeliverCard;
